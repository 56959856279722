/* You can add global styles to this file, and also import other style files */
.list-group {
  max-height: 50vh;
  overflow-y: auto;
  border-radius: 0 !important;
}

.bg-warning {
  background-color: #ffc800 !important;
}

.badge-cancelled {
  background-color: #f80031;
  color: white;
}

.badge-active {
  background-color: rgba(147, 231, 195, 0.5);
  color: #1aae6f;
}

.alert.alert-important {
  border-left: 6px solid #f83535;
}

.alert.alert-special {
  background-color: #e6f8ff;
  color: #000;
}

.ck-editor__editable_inline {
  min-height: 500px;
}

.invalid-feedback {
  display: block;
}

li.list-group-item {
  padding: 6px 10px;
}

.tab-pane {
  min-height: 70vh;
}

.table td {
  padding: 0.3rem 1.5rem;
}

.badge-success {
  background-color: rgb(198 255 230);
}

.badge-secondary {
  color: #f4f4f4;
  font-size: 10px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #fff;
  cursor: pointer;
  opacity: 1;
}

.form-control-alternative {
  border: 1px solid #cad1d7;
}

small {
  margin-bottom: 5px;
  margin-top: 10px;
}

.table-summary td:nth-child(1) {
  font-size: 1rem;
}

.table-summary td:nth-child(2) {
  background-color: #cfe2ff;
}

.table-summary td:nth-child(3) {
  background-color: #9ec5fe;
  color: #fff;
}

.table-summary td:nth-child(4) {
  background-color: #6ea8fe;
  color: #fff;
}

.table-summary td:nth-child(5) {
  background-color: #3d8bfd;
  color: #fff;
}

input.form-control.w-25 {
  display: inline-block;
}
