//
// Button
//

@import "../core/buttons/button";
@import "../core/buttons/button-icon";
@import "../core/buttons/button-brand";

.rider-radio label.active {
    border: 1px solid #a60efd;
}
